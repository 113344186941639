import { graphql } from "gatsby"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Appear,
  CaseStudies,
  Filters,
  LetsTalk,
  LogoTransitionType,
  SearchBox,
  SEO,
} from "../components"
import { useLayout } from "../context"
import { CaseStudy } from "../models/CaseStudy"
import { getUniqueTags } from "../utilities"
import { useOnClickOutside } from "../hooks"
import SearchIcon from "../svg/search.svg"

interface WorkPage {
  id: string
  seoMetaTags: object
  title: string
}

interface Props {
  data: {
    page: WorkPage
    caseStudies: {
      edges: Array<{
        node: CaseStudy
      }>
      totalCount: number
    }
  }
}

const Work = (props: Props) => {
  const { seoMetaTags, title } = props.data.page
  const caseStudies = props.data.caseStudies.edges.map((edge) => edge.node)
  const { geoData, startLogoTransition } = useLayout()
  const [search, setSearch] = useState<string>("")
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false)
  const searchRef = useRef(null)

  useEffect(() => {
    startLogoTransition(LogoTransitionType.JumpAndSpin)
  }, [search])

  // Items filter
  const tags = useMemo(() => getUniqueTags(caseStudies, "All Work"), [caseStudies])

  const [selectedTag, setSelectedTag] = useState(tags[0])
  const selectTag = (tag: string) => {
    setSelectedTag(tag)
    startLogoTransition(LogoTransitionType.JumpAndSpin)
  }

  useOnClickOutside(searchRef, () => {
    if (!search) {
      setIsSearchVisible(false)
    }
  })

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />

      <div className="container">
        <div className="mb-6 md:mb-16">
          <Appear order={1} hideOnExit={true}>
            <h1 className="heading-l lg:heading-xl mb-4 md:mb-0">{title}</h1>
          </Appear>
        </div>

        {/* <Appear order={2}>
          <div
            className="flex items-start md:items-center"
            style={{ minHeight: "42px" }}
          >
            <div
              className="flex items-start md:items-center flex-shrink-0"
              ref={searchRef}
            >
              <button
                onClick={() => {
                  setIsSearchVisible(!isSearchVisible)
                  setSearch("")
                }}
                className="focus:outline-none"
              >
                <SearchIcon width="24" height="24" className="mr-4" />
              </button>
              {isSearchVisible && (
                <SearchBox onChange={(phrase) => setSearch(phrase)} />
              )}
            </div>
            {!isSearchVisible && (
              <div className="flex-grow">
                <Filters
                  filters={tags}
                  selected={selectedTag}
                  setSelected={selectTag}
                />
              </div>
            )}
          </div>
        </Appear> */}
      </div>

      <div className="container px-0 md:px-5 mt-4 pb-4 lg:pb-8">
        <CaseStudies geoData={geoData} items={caseStudies} search={search} tag={selectedTag} />
      </div>

      <LetsTalk />
    </>
  )
}

export const query = graphql`
  query WorkPageQuery($locale: String!) {
    page: datoCmsWork(locale: { eq: $locale }) {
      title
      seoMetaTags {
        tags
      }
    }
    caseStudies: allDatoCmsCaseStudy(
      filter: { locale: { eq: $locale } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          id
          client
          priority
          slug
          tags
          title
          thumbnail {
            gatsbyImageData(width: 616, imgixParams: { auto: "format" })
          }
          thumbnailVideo {
            ...VideoFileFragment
          }
        }
      }
      totalCount
    }
  }
`

export default Work
